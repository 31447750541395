import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {
    getAuth,
    RecaptchaVerifier,
    signInWithPhoneNumber,
} from "firebase/auth";
import PhoneInput from 'react-phone-input-2'

import 'react-phone-input-2/lib/style.css'
import '../tailwind.css';
import './style.css';
// import PlaidIframeComponent from './PlaidIframeComponent';

function Phone() {
    const navigate = useNavigate();
    const [activeComponent, setActiveComponent] = useState('Home');
    const [containerPlatform, setContainerPlatform] = useState('web'); // web | webview

    // var initHasAccount = false;

    // const loginId = localStorage.getItem("flinks_loginId");
    // if (loginId !== undefined && loginId != null) {
    //   initHasAccount = true;
    //   setActiveComponent("Component4");
    // } else {
    //   initHasAccount = false;
    //   setActiveComponent("Component1");
    // }

    const recaptchaRef = React.createRef();
    const [phoneNumber, setPhoneNumber] = useState('');
    const [isPhoneValid, setIsPhoneValid] = useState(false);
    const [isPhoneSubmitted, setIsPhoneSubmitted] = useState(false);
    const [phoneVerification, setPhoneVerification] = useState();
    const [maxPlaidItems, setMaxPlaidItems] = useState(100);
    const [availablePlaidItems, setAvailablePlaidItems] = useState(80);
    // const [hasAccount, setHasAccount] = useState(initHasAccount);
    // const [submitCount, setSubmitCount] = useState(0);

    // const onMessageReceived = (e) => {
    //   if (e.data.step === 'REDIRECT' && 'loginId' in e.data && e.data.loginId !== undefined && submitCount === 0) {
    //     window.removeEventListener("message", onMessageReceived);

    //     localStorage.setItem("flinks_loginId", e.data.loginId);

    //     if (email !== null && email.length > 0 && e.data.loginId !== null && e.data.loginId.length > 0) {
    //       // setHasAccount(true);
    //       setSubmitCount(submitCount + 1);
    //       setActiveComponent("Component4");

    //       const formData = {
    //         email: email,
    //         loginId: e.data.loginId,
    //       };

    //       // Sending the form data to Firebase Functions
    //       fetch(process.env.REACT_APP_CANCELLY_API_URL + '/submit', {
    //         method: 'POST',
    //         headers: { "Content-type": "application/json" },
    //         body: JSON.stringify(formData)
    //       })
    //         .then((response) => response.json())
    //         .then((data) => {
    //           // Handle response from Firebase Functions
    //           console.log(data);
    //         })
    //         .catch((error) => {
    //           // Handle error
    //           console.error(error);
    //         });
    //     }

    //     // setHasAccount(true);
    //     setActiveComponent("Component4");
    //   }
    // };

    // window.addEventListener("message", onMessageReceived, true);

    // const [isLoadingIFrame, setIsLoadingIFrame] = useState(!initHasAccount);

    // const handleIframeLoad = () => {
    //   setIsLoadingIFrame(false);
    // };

    // useEffect(() => {
    //     fetch(process.env.REACT_APP_CANCELLY_API_URL + '/plaid/usage', {
    //         method: 'POST',
    //         headers: {
    //             "Content-type": "application/json",
    //         },
    //     })
    //         .then((response) => response.json())
    //         .then((res) => {
    //             if (res.status === "ok" && res.count > 80 && res.count <= 100) {
    //                 setAvailablePlaidItems(res.count);
    //             }
    //         })
    //         .catch((error) => {
    //             // Handle error
    //             console.error(error);
    //         });
    // }, []);

    // useEffect(() => {
    //     if (window.hasOwnProperty('ReactNativeWebView') && window.ReactNativeWebView.hasOwnProperty('postMessage')) {
    //         setContainerPlatform('webview');
    //     }
    // }, [window.ReactNativeWebView]);

    const setReCaptcha = (size = 'invisible') => {
        if (!recaptchaRef) {
            return;
        }

        try {
            const auth = getAuth();
            window.recaptchaVerifier = new RecaptchaVerifier('recaptcha-container', {
                'size': size,
                'callback': (response) => {
                    console.log('recaptcha-container callback', response);
                },
                'expired-callback': () => {
                    // Response expired. Ask user to solve reCAPTCHA again.
                    if (window.recaptchaWidgetId && window.grecaptcha) {
                        window.grecaptcha.reset(window.recaptchaWidgetId);
                    }
                    setReCaptcha('normal');
                }
            }, auth);
            window.recaptchaVerifier.render().then((widgetId) => {
                window.recaptchaWidgetId = widgetId;
            });
        } catch (err) {
            console.error('Error setting up captcha', err);
        }
    };

    useEffect(() => {
        setReCaptcha();
    }, []);

    const handlePhoneChange = (value, country) => {
        setPhoneNumber(value);
        setIsPhoneValid(validatePhone(value));
    };

    const validatePhone = (phone) => {
        // Basic phone validation using a regular expression
        const phonePattern = /[0-9]{10,}$/;
        return phonePattern.test(phone);
    };

    const handleSubmit = (event) => {
        event.preventDefault();

        const elements = event.target.elements;

        if (elements.smsCode && elements.smsCode.value) {
            const code = elements.smsCode.value;
            phoneVerification.confirm(code).then((result) => {
                const user = result.user;
                // FIXME: we need email address
                // window.localStorage.setItem("email", user.email);
                navigate('/new-profile');
            }).catch((error) => {
                // User couldn't sign in (bad verification code?)
                // ...
                alert(error.message);
            });
        } else if (!isPhoneSubmitted && phoneNumber !== null && phoneNumber.length > 0) {
            console.log('Submitting...', phoneNumber);

            //saveStats();
            localStorage.setItem("phone", phoneNumber);

            const auth = getAuth();
            auth.useDeviceLanguage();

            const appVerifier = window.recaptchaVerifier;
            signInWithPhoneNumber(auth, '+' + phoneNumber, appVerifier)
                .then((confirmationResult) => {
                    window.confirmationResult = confirmationResult;
                    setPhoneVerification(confirmationResult);
                })
                .catch((error) => {
                    const errorMsg = error.toString().replace(/firebase/gi, '');
                    alert(errorMsg);
                    setIsPhoneSubmitted(false);
                    //navigate("/404");
                });

            setIsPhoneSubmitted(true);

            // childRef.current.handlePlaidLink();

            // navigate('/connect');
            // setActiveComponent('Plaid');

            // setTimeout(function () {
            //   scrollToIframe();
            // }, 500);
        }
    };

    // const scrollToIframe = () => {
    //     const container = document.getElementById('plaid-container');
    //     const iframe = container.querySelector('iframe');
    //     if (iframe) {
    //         console.log(iframe);
    //         const duration = 5;
    //         const { x, y } = iframe.getBoundingClientRect();

    //         console.log('x, y', x, y);

    //         const scrollStep = Math.PI / (duration / 15);
    //         let count = 0;
    //         let curPosition = {
    //             x: window.scrollX,
    //             y: window.scrollY
    //         };

    //         let animationRunning = true;

    //         const scrollAnimation = () => {
    //             if (!animationRunning) return;

    //             if (window.scrollX !== x || window.scrollY !== y) {
    //                 window.scrollTo(curPosition.x + ((x - curPosition.x) / duration) * count,
    //                     curPosition.y + ((y - curPosition.y) / duration) * count);
    //                 count += 1;
    //                 setTimeout(scrollAnimation, 50);
    //             } else {
    //                 animationRunning = false;
    //             }
    //         };

    //         scrollAnimation();
    //     }
    // };

    // const [shouldShowFileUploader, setShouldShowFileUploader] = useState(false);

    const showFileUploader = (event) => {
        event.preventDefault();
        // setShouldShowFileUploader(true);
        console.log(phoneNumber);
        navigate('/csv');
    };

    const handlePlaidFinish = (publicToken) => {
        navigate('/thanks');
    };

    return (
        <div>
            <div className={`App-Component component-wrapper ${activeComponent !== 'Home' ? 'hidden' : ''}`}>
                <div class="flex flex-row lg:flex-col">
                    <div class="w-2/5 lg:w-full">
                        <form onSubmit={handleSubmit} className="Form p-5 mt-5 border-solid border-black">
                            <div className="block">
                                <h1 className="Form-Header text-4xl font-bold">
                                    Own your data.<br />share or delete<br /><span className="Bold">any time</span>.
                                </h1>
                                {isPhoneSubmitted ? <div>
                                    <div className="Form-Placeholder">
                                        <label htmlFor="email">Check your messages for a one-time code</label>
                                    </div>
                                    <br />
                                    <input type="text" name="smsCode" autocomplete="one-time-code" pattern="\d{6}" required
                                        className="Form-Input border-solid placeholder:text-gray-500 border-[1px] border-gray-500 w-[340px] lg:w-[300px] p-[7px] text-2xl"
                                    />
                                </div>
                                    : <div>
                                        <div className="Form-Placeholder">
                                            <label htmlFor="email">Let's start with your mobile number</label>
                                        </div>
                                        <br />
                                        <PhoneInput
                                            type="tel"
                                            name="phone"
                                            country="ca"
                                            preferredCountries={['ca', 'us', 'uk', 'de', 'fr', 'in', 'pk']}
                                            enableSearch={true}
                                            value={phoneNumber}
                                            onChange={handlePhoneChange}
                                            placeholder="Mobile Number"
                                            dropdownClass="w-[32px]"
                                            inputClass="Form-Input border-solid placeholder:text-gray-500 border-[1px] border-gray-500 w-[340px] lg:w-[300px] p-[7px] text-2xl"
                                            required
                                        />
                                        <div ref={recaptchaRef} id="recaptcha-container" className='mt-5'></div>
                                    </div>}
                                <div className='mt-5 flex'>
                                    <div id="sign-in-button"></div>
                                    {containerPlatform === 'web' && <button type="button" className="xs:text-xs text-sm h-[50px] rounded-non border-gray border-solid border-2">
                                        <div className="flex p-2">
                                            <a href="/home">Sign in with Email</a>
                                        </div>
                                    </button>}
                                    <button type="submit" className="Form-Button xs:text-xs text-sm rounded-none text-white bg-black ml-2 mt-0 px-8">
                                        Verify</button>
                                </div>
                                <p className="Form-Tooltip">Try it FREE: No Credit Card Required.</p>
                            </div>
                        </form>
                        <ol className="Notes list-decimal">
                            <li>
                                <div className='question'>What is Cancelly?</div>
                                <div>Cancelly is an app to manage recurring subscriptions, bank fees, interest charges, identities and personal data. It can consolidate your debt, file taxes, and come with a free Chrome Extension for tracking trial sign-ups to avoid unintentional charges. Third parties can request your personal data from varying sources and you can approve or decline.</div>
                            </li>
                            <li>
                                <div className='question'>How does Cancelly work?</div>
                                <div>After putting your email address and connecting your bank accounts with <b>trusted third party</b> (Flinks or Plaid), our Machine Learning pipeline will analyze your transactions for fraudulent recurring subscriptions and send you a detailed email.</div>
                            </li>
                            <li>
                                <div className='question'>Can a business/corporation use Cancelly?</div>
                                <div><b>Absolutely!</b> Cancelly is a business first solution for your banking transactions. As a business ourself we feel the pain with fraudulent recurring subscriptions.</div>
                            </li>
                            <li>
                                <div className='question'>Do you have access to or do you store my bank username and password?</div>
                                <div><b>We do not</b> and can not see your online banking username or password. The third party takes care of that for us and we simply get your transactions to feed to our Machine Learning.</div>
                            </li>
                        </ol>
                    </div>
                    <div className="w-3/5 lg:w-full">
                        <div className="ml-12 -mt-16 -mr-10 lg:ml-0 lg:mt-2 lg:mr-0 flex flex-rows">
                            <div className="">
                                <img
                                    src="./assets/web/cancelly_subscriptions_demo1.png"
                                    alt="Cancelly Subscriptions Demo"
                                    className="opacity-60" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className={`App-Component component-wrapper ${activeComponent !== 'Plaid' ? 'hidden' : ''}`}>
                <div className="App-Component component-wrapper">
                    <div>
                        <h2>Lastly we need your bank account(s)</h2>
                        <p>Or use a csv file uploader <button onClick={showFileUploader}>click here</button></p>
                        {/* <iframe className="flinksconnect" height="760" title="flinks"
              src="https://toolbox-iframe.private.fin.ag/?demo=true&redirectUrl=https://flinks.com/contact/thank-you&innerRedirect=true&theme=light&consentEnable=true&customerName=FinTech&backgroundColor=f7f7f7&foregroundColor1=000000&desktopLayout=true&headerEnable=false&institutionFilterEnable=true"></iframe> */}
                        {/* <PlaidIntegration ref={childRef} onFinish={() => { setActiveComponent("Component4") }} /> */}
                        {/* <PlaidIframeComponent email={email} onFinish={handlePlaidFinish} /> */}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Phone;
