import './TalentScreen.css';

function TalentScreen() {
    return (
        <div className="App-Component component-wrapper w-full">
            <h1 className="text-2xl mt-10 lg:mt-2 mb-4 down w-[600px] lg:w-full">
                Delegate your most important tasks lagging behind and instantly get results you want
            </h1>
            <h3 className="w-[600px] lg:w-full mt-10 mb-4 lg:mb-6">Are you a freelancer, service provider or seller? <a href="/new-profile" className="border-b-2">Create your profile!</a></h3>
            <div className="flex flex-row lg:flex-col w-[550px] lg:w-full mt-10 lg:m-auto">
                <div className="w-full lg:w-[200px] lg:m-auto h-[260px] relative mr-5 lg:mb-4">
                    <img src="/assets/web/avatar_male2.png" className="m-auto bg-white rounded-[75px] w-[150px] h-[150px]" />
                    <b className="block mt-2 break-normal text-wrap">Marc Antoine Lachance</b>
                    <a href="/message-talent?id=1" className="absolute w-full left-0 bottom-0 px-6 py-2 font-medium tracking-wide text-white capitalize bg-yellow-400 rounded-lg">Send Message</a>
                </div>
                <div className="w-full lg:w-[200px] lg:m-auto h-[260px] relative mr-5 lg:mb-4">
                    <img src="/assets/web/avatar_female.png" className="m-auto bg-white rounded-[75px] w-[150px] h-[150px]" />
                    <b className="block mt-2 break-normal text-wrap">Chantal Gingras</b>
                    <a href="/message-talent?id=2" className="absolute w-full left-0 bottom-0 px-6 py-2 font-medium tracking-wide text-white capitalize bg-yellow-400 rounded-lg">Send Message</a>
                </div>
                <div className="w-full lg:w-[200px] lg:m-auto h-[260px] relative lg:mb-4">
                    <img src="/assets/web/avatar_male.png" className="m-auto bg-white rounded-[75px] w-[150px] h-[150px]" />
                    <b className="block mt-2 break-normal text-wrap">Philippe Dufresne</b>
                    <a href="/message-talent?id=3" className="absolute w-full left-0 bottom-0 px-6 py-2 font-medium tracking-wide text-white capitalize bg-yellow-400 rounded-lg">Send Message</a>
                </div>
            </div>
            <div className="w-[600px] lg:w-full mt-10">
                <img src="/assets/web/five_star_rating.jpg" className="m-auto w-[200px] rounded-[100px]" />
            </div>
            <div className="absolute lg:relative top-0 right-0 w-[800px] lg:w-full mt-20 lg:mt-10">
                <iframe src='https://widgets.sociablekit.com/tiktok-hashtag-feed/iframe/25512533' frameborder='0' width='100%' height='1000' className=""></iframe>
            </div>
        </div>
    );
}

export default TalentScreen;
