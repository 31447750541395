import { useEffect, useState } from 'react';
import moment from 'moment';
import './style.css';

function JobsScreen() {
    const [jobs, setJobs] = useState([]);

    useEffect(() => {
        if (process.env.NODE_ENV === 'development') {
            setJobs([
                {
                    title: 'JavaScript, React, Node.js Google Firebase Developer',
                    pay_type: 'Hourly',
                    experience_level: 'Expert', // Beginner, Intermediate, Expert
                    budget: '$20-$60/hr',
                    details: `Here are some key aspects of the job description:
                            Full Stack Development: Develop and maintain web applications using JavaScript, React for the frontend, and Node.js for the backend. Implement responsive designs and ensure cross-browser compatibility.
                            Database Management: Utilize Google Firebase for real-time database management, user authentication, and cloud functions. Work with both non-relational and relational databases like Firebase Firestore, MongoDB, PostgreSQL, or similar.
                            Cloud Technologies: Deploy and manage applications using cloud services such as Google Cloud Platform, Firebase, and other cloud providers. Ensure applications are scalable and secure.
                            API Development: Build and maintain RESTful APIs to facilitate communication between the frontend and backend. Use tools like Axios for API calls and ensure APIs are secure and efficient.
                            Frontend Frameworks: Proficient in React and Redux for state management. Implement UI/UX designs and ensure the application is user-friendly and accessible.
                            Backend Development: Develop server-side logic using Node.js and Express.js. Implement server-side APIs and ensure they are robust and scalable.
                            Testing and Quality Assurance: Write unit tests and integration tests to ensure the quality of the code. Use automated testing libraries and methods to maintain high code quality.
                            CI/CD Pipelines: Set up and maintain continuous integration and continuous deployment pipelines to automate the build and deployment process. Ensure code is maintainable, scalable, and actively reduces technical debt.
                            Collaboration: Work closely with cross-functional teams including designers, backend developers, and product managers to deliver high-quality products. Ensure the technical feasibility of UI/UX designs.
                            Documentation: Write clear and concise documentation for the codebase and APIs. Ensure the documentation is up-to-date and easily understandable for other developers.
                            Problem Solving: Troubleshoot and resolve issues that arise during development and deployment. Provide technical solutions to ensure the smooth operation of applications.
                            Staying Updated: Keep up-to-date with the latest trends and best practices in web development. Continuously learn and adapt to new technologies and frameworks.
                            This role requires strong proficiency in JavaScript, React, Node.js, and Firebase, as well as experience with cloud technologies and deployment strategies.`,
                    tags: ['JavaScript', 'Node.js', 'React'],
                    payment_verified: 'Payment unverified',
                    rating: 0, // out of 5
                    country_name: 'Canada',
                    client_spent: '$0',
                    proposals_count: 'Less than 5',
                },
                {
                    title: 'JavaScript, React, Node.js Google Firebase Developer',
                    pay_type: 'Hourly',
                    experience_level: 'Expert', // Beginner, Intermediate, Expert
                    budget: '$20-$60/hr',
                    details: `Here are some key aspects of the job description:
                            Full Stack Development: Develop and maintain web applications using JavaScript, React for the frontend, and Node.js for the backend. Implement responsive designs and ensure cross-browser compatibility.
                            Database Management: Utilize Google Firebase for real-time database management, user authentication, and cloud functions. Work with both non-relational and relational databases like Firebase Firestore, MongoDB, PostgreSQL, or similar.
                            Cloud Technologies: Deploy and manage applications using cloud services such as Google Cloud Platform, Firebase, and other cloud providers. Ensure applications are scalable and secure.
                            API Development: Build and maintain RESTful APIs to facilitate communication between the frontend and backend. Use tools like Axios for API calls and ensure APIs are secure and efficient.
                            Frontend Frameworks: Proficient in React and Redux for state management. Implement UI/UX designs and ensure the application is user-friendly and accessible.
                            Backend Development: Develop server-side logic using Node.js and Express.js. Implement server-side APIs and ensure they are robust and scalable.
                            Testing and Quality Assurance: Write unit tests and integration tests to ensure the quality of the code. Use automated testing libraries and methods to maintain high code quality.
                            CI/CD Pipelines: Set up and maintain continuous integration and continuous deployment pipelines to automate the build and deployment process. Ensure code is maintainable, scalable, and actively reduces technical debt.
                            Collaboration: Work closely with cross-functional teams including designers, backend developers, and product managers to deliver high-quality products. Ensure the technical feasibility of UI/UX designs.
                            Documentation: Write clear and concise documentation for the codebase and APIs. Ensure the documentation is up-to-date and easily understandable for other developers.
                            Problem Solving: Troubleshoot and resolve issues that arise during development and deployment. Provide technical solutions to ensure the smooth operation of applications.
                            Staying Updated: Keep up-to-date with the latest trends and best practices in web development. Continuously learn and adapt to new technologies and frameworks.
                            This role requires strong proficiency in JavaScript, React, Node.js, and Firebase, as well as experience with cloud technologies and deployment strategies.`,
                    tags: ['JavaScript', 'Node.js', 'React'],
                    payment_verified: 'Payment unverified',
                    rating: 0, // out of 5
                    country_name: 'Canada',
                    client_spent: '$0',
                    proposals_count: 'Less than 5',
                },
                {
                    title: 'JavaScript, React, Node.js Google Firebase Developer',
                    pay_type: 'Hourly',
                    experience_level: 'Expert', // Beginner, Intermediate, Expert
                    budget: '$20-$60/hr',
                    details: `Here are some key aspects of the job description:
                            Full Stack Development: Develop and maintain web applications using JavaScript, React for the frontend, and Node.js for the backend. Implement responsive designs and ensure cross-browser compatibility.
                            Database Management: Utilize Google Firebase for real-time database management, user authentication, and cloud functions. Work with both non-relational and relational databases like Firebase Firestore, MongoDB, PostgreSQL, or similar.
                            Cloud Technologies: Deploy and manage applications using cloud services such as Google Cloud Platform, Firebase, and other cloud providers. Ensure applications are scalable and secure.
                            API Development: Build and maintain RESTful APIs to facilitate communication between the frontend and backend. Use tools like Axios for API calls and ensure APIs are secure and efficient.
                            Frontend Frameworks: Proficient in React and Redux for state management. Implement UI/UX designs and ensure the application is user-friendly and accessible.
                            Backend Development: Develop server-side logic using Node.js and Express.js. Implement server-side APIs and ensure they are robust and scalable.
                            Testing and Quality Assurance: Write unit tests and integration tests to ensure the quality of the code. Use automated testing libraries and methods to maintain high code quality.
                            CI/CD Pipelines: Set up and maintain continuous integration and continuous deployment pipelines to automate the build and deployment process. Ensure code is maintainable, scalable, and actively reduces technical debt.
                            Collaboration: Work closely with cross-functional teams including designers, backend developers, and product managers to deliver high-quality products. Ensure the technical feasibility of UI/UX designs.
                            Documentation: Write clear and concise documentation for the codebase and APIs. Ensure the documentation is up-to-date and easily understandable for other developers.
                            Problem Solving: Troubleshoot and resolve issues that arise during development and deployment. Provide technical solutions to ensure the smooth operation of applications.
                            Staying Updated: Keep up-to-date with the latest trends and best practices in web development. Continuously learn and adapt to new technologies and frameworks.
                            This role requires strong proficiency in JavaScript, React, Node.js, and Firebase, as well as experience with cloud technologies and deployment strategies.`,
                    tags: ['JavaScript', 'Node.js', 'React'],
                    payment_verified: 'Payment unverified',
                    rating: 0, // out of 5
                    country_name: 'Canada',
                    client_spent: '$0',
                    proposals_count: 'Less than 5',
                },
            ]);
        }
    }, []);

    return (
        <div className="App-Component component-wrapper w-full mt-10 bg-gray-100 text-gray-700">
            {jobs && jobs.length === 0
                ? <div>
                    <p>No jobs found at this time. Please check back later</p>
                </div>
                : <div className="flex">
                    <div className="flex-none w-80">
                        <h3 className="">Filters</h3>
                        <form className="text-left p-2">
                            <h4>Payment Type</h4>
                            <label for="pay_type_all">
                                <input id="pay_type_all" name="pay_type" value="All" type="radio" checked /> All
                            </label>
                            <br />
                            <label for="pay_type_hourly">
                                <input id="pay_type_hourly" name="pay_type" value="Hourly" type="radio" /> Hourly
                            </label>
                            <br />
                            <label for="pay_type_fixed">
                                <input id="pay_type_fixed" name="pay_type" value="Fixed" type="radio" /> Fixed Price
                            </label>
                            <br />
                            <br />
                            <h4>Experience</h4>
                            <label for="experience_level_all">
                                <input id="experience_level_all" name="experience_level_all" value="All" type="radio" checked /> All
                            </label>
                            <br />
                            <label for="experience_level_beginner">
                                <input id="pay_type_beginner" name="pay_type_beginner" value="Beginner" type="radio" /> Beginner
                            </label>
                            <br />
                            <label for="experience_level_intermediate">
                                <input id="experience_level_intermediate" name="experience_level_intermediate" value="Intermediate" type="radio" /> Intermediate
                            </label>
                            <br />
                            <label for="experience_level_expert">
                                <input id="experience_level_expert" name="experience_level_expert" value="Expert" type="radio" /> Expert
                            </label>
                        </form>
                    </div>
                    <div className="flex-auto text-left">
                        {jobs && jobs.map(job => <div className="mt-2 border-2 px-4 py-2 hover:bg-gray-200">
                            <p className="text-sm leading-6">Posted {moment().fromNow()}</p>
                            <h1 className="text-2xl leading-8 hover:text-black hover:cursor-pointer">{job.title}</h1>
                            <span className="text-sm leading-8">{job.pay_type} Price</span> - <span className="text-sm">{job.experience_level}</span> - <span className="text-sm">Budget: {job.budget}</span>
                            <p className="w-full max-h-[15vh] overflow-hidden text-ellipsis">
                                {job.details}
                            </p>
                            <p className="mt-4 leading-8">
                                {job.tags.map(tag => <span className="px-4 py-2 mr-1 rounded-3xl bg-gray-300">{tag}</span>)}
                            </p>
                            <p className="mt-2 leading-8">
                                <span className="text-sm">{job.payment_verified}</span> - <span className="text-sm">{job.rating}</span> - <span className="text-sm">{job.client_spent} spent</span> - <span className="text-sm">{job.country_name}</span>
                            </p>
                        </div>)}
                    </div>
                </div>
            }
        </div>
    );
}

export default JobsScreen;
