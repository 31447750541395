import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { getAuth, onAuthStateChanged, RecaptchaVerifier, EmailAuthProvider, updateEmail, PhoneAuthProvider, updatePhoneNumber, updateProfile } from "firebase/auth";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";


import './NewProfileScreen.css';

function NewProfileScreen() {
    const navigate = useNavigate();
    const [user, setUser] = useState();
    const [photoURL, setPhotoURL] = useState();
    const [displayName, setDisplayName] = useState();
    const [email, setEmail] = useState();
    const [phoneNumber, setPhoneNumber] = useState(); // +16473629644
    const [phoneVerification, setPhoneVerification] = useState();

    useEffect(() => {
        const auth = getAuth();
        onAuthStateChanged(auth, (user) => {
            setUser(user);
            setPhotoURL(user.photoURL);
            setDisplayName(user.displayName);
            setEmail(user.email);
            setPhoneNumber(user.phoneNumber);
        });
    }, []);

    const uploadImageAsync = async (file) => {
        try {
            const auth = getAuth();
            const storage = getStorage();
            const storageRef = ref(storage, "user/" + auth.currentUser.uid + "/" + file.name);
            const snapshot = await uploadBytes(storageRef, file);
            const downloadURL = await getDownloadURL(snapshot.ref);
            return downloadURL;
        } catch (err) {
            throw new Error(err)
        }
    };

    const sendPhoneOTP = async (event) => {
        event.preventDefault();

        const auth = getAuth();
        const phoneProvider = new PhoneAuthProvider(auth);
        const verifier = new RecaptchaVerifier('recaptcha-container', {
            callback: (response) => console.log('callback', response),
            size: 'invisible',
        }, auth);
        console.log('Sending code to', phoneNumber);
        const confirmationId = await phoneProvider.verifyPhoneNumber(phoneNumber, verifier);
        setPhoneVerification(confirmationId);

        return false;
    };

    const onSubmit = async (event) => {
        event.preventDefault();

        const userData = {};

        const elements = event.target.elements;

        const files = elements.displayPicture.files;
        if (files.length > 0) {
            const file = files[0];
            const url = await uploadImageAsync(file);
            if (url) {
                userData['photoURL'] = url;
            }
        }

        if (elements.fullName.value) {
            userData['displayName'] = elements.fullName.value;
        }

        const auth = getAuth();
        updateProfile(auth.currentUser, userData);

        if (elements.email.value) {
            try {
                await updateEmail(user, elements.email.value);
            } catch (err) {
                console.log(err.code, err.message);
                if (err.code === 'auth/email-already-in-use') {
                    alert('Error: Email already in use! Profile saved but email not saved!');
                }
            }
        }

        if (elements.phoneOTP.value) {
            try {
                const cred = PhoneAuthProvider.credential(phoneVerification, elements.phoneOTP.value);
                await updatePhoneNumber(user, cred);
            } catch (err) {
                console.log(err.code, err.message);
                if (err.code === 'auth/phone-already-in-use') {
                    alert('Error: Phone already in use! Profile saved but phone not saved!');
                }
            }
        }

        if (window.confirm('Profile Saved! Press Ok to navigate to dashboard.')) {
            navigate('/dashboard');
        }

        return false;
    }

    if (!user) {
        return;
    }

    return (
        <section class="mt-10 mb-10 mx-auto lg:mx-4 p-2 max-w-4xl p-6 bg-white rounded-md shadow-md dark:bg-gray-800">
            <h2 class="text-lg font-semibold text-gray-700 capitalize dark:text-white">Create your profile</h2>
            <form onSubmit={onSubmit}>
                <div class="grid grid-cols-1 gap-6 mt-4">
                    <div>
                        {photoURL && <div className="w-[150px] h-[150px] border-1 rounded-[75px] m-auto">
                            <img src={photoURL} alt={displayName} className="h-full" />
                        </div>}
                        <label for="displayPicture" class="text-gray-700 dark:text-gray-200">Display Picture</label>
                        <input id="displayPicture" type="file" accept="image/png,image/jpeg" class="block w-full px-4 py-2 mt-2 text-sm text-gray-600 bg-white border border-gray-200 rounded-lg file:bg-gray-200 file:text-gray-700 file:text-sm file:px-6 file:py-1 file:border-none file:rounded-full dark:file:bg-gray-800 dark:file:text-gray-200 dark:text-gray-300 placeholder-gray-400/70 dark:placeholder-gray-500 focus:border-blue-400 focus:outline-none focus:ring focus:ring-blue-300 focus:ring-opacity-40 dark:border-gray-600 dark:bg-gray-900 dark:focus:border-blue-300" />
                    </div>

                    <div>
                        <label class="text-gray-700 dark:text-gray-200" for="fullName">Full Name</label>
                        <input id="fullName" type="text" placeholder="John Wick" value={displayName}
                            onChange={e => setDisplayName(e.target.value)}
                            class="block w-full px-4 py-2 mt-2 text-gray-700 bg-white border border-gray-200 rounded-md dark:bg-gray-800 dark:text-gray-300 dark:border-gray-600 focus:border-blue-400 focus:ring-blue-300 focus:ring-opacity-40 dark:focus:border-blue-300 focus:outline-none focus:ring" />
                    </div>

                    <div>
                        <label class="text-gray-700 dark:text-gray-200" for="email">Email <p className="text-xs">Not Shown Publicly</p></label>
                        <div className="flex">
                            <input id="email" type="email" placeholder="john@gmail.com" value={email}
                                onChange={e => setEmail(e.target.value)}
                                class="block w-full px-4 py-2 mt-2 text-gray-700 bg-white border border-gray-200 rounded-md dark:bg-gray-800 dark:text-gray-300 dark:border-gray-600 focus:border-blue-400 focus:ring-blue-300 focus:ring-opacity-40 dark:focus:border-blue-300 focus:outline-none focus:ring" />
                        </div>
                    </div>

                    <div>
                        <label class="text-gray-700 dark:text-gray-200" for="phoneNumber">Phone <p className="text-xs">Not Shown Publicly</p></label>
                        <div className="flex">
                            <input id="phoneNumber" type="text" placeholder="+15146595123" value={phoneNumber}
                                onChange={e => setPhoneNumber(e.target.value)}
                                class="block w-full px-4 py-2 mt-2 text-gray-700 bg-white border border-gray-200 rounded-md dark:bg-gray-800 dark:text-gray-300 dark:border-gray-600 focus:border-blue-400 focus:ring-blue-300 focus:ring-opacity-40 dark:focus:border-blue-300 focus:outline-none focus:ring" />
                            <button onClick={sendPhoneOTP} class="ml-2 px-8 py-2.5 leading-5 text-white transition-colors duration-300 transform bg-gray-700 rounded-md hover:bg-gray-600 focus:outline-none focus:bg-gray-600">Send OTP</button>
                        </div>
                    </div>

                    <div>
                        <label class="text-gray-700 dark:text-gray-200" for="phoneOTP">Phone OTP</label>
                        <input id="phoneOTP" type="text" placeholder="243657" class="block w-full px-4 py-2 mt-2 text-gray-700 bg-white border border-gray-200 rounded-md dark:bg-gray-800 dark:text-gray-300 dark:border-gray-600 focus:border-blue-400 focus:ring-blue-300 focus:ring-opacity-40 dark:focus:border-blue-300 focus:outline-none focus:ring" />
                    </div>

                    {/* <div>
                        <label class="text-gray-700 dark:text-gray-200" for="title">Title</label>
                        <input id="title" type="email" placeholder="E.g. CPA, Accountant, Debt Consultant, Trustee, etc" class="block w-full px-4 py-2 mt-2 text-gray-700 bg-white border border-gray-200 rounded-md dark:bg-gray-800 dark:text-gray-300 dark:border-gray-600 focus:border-blue-400 focus:ring-blue-300 focus:ring-opacity-40 dark:focus:border-blue-300 focus:outline-none focus:ring" />
                    </div> */}

                    {/* <div>
                        <label for="birthday" class="block text-gray-700 dark:text-gray-200">Date of Birth</label>
                        <input id="birthday" type="date" placeholder="John Doe" class="block  mt-2 w-full placeholder-gray-400/70 dark:placeholder-gray-500 rounded-lg border border-gray-200 bg-white px-5 py-2.5 text-gray-700 focus:border-blue-400 focus:outline-none focus:ring focus:ring-blue-300 focus:ring-opacity-40 dark:border-gray-600 dark:bg-gray-900 dark:text-gray-300 dark:focus:border-blue-300" />
                    </div> */}
                </div>

                <div class="flex justify-end mt-6">
                    <button type="submit" class="px-8 py-2.5 leading-5 text-white transition-colors duration-300 transform bg-gray-700 rounded-md hover:bg-gray-600 focus:outline-none focus:bg-gray-600">Save</button>
                </div>
            </form>
        </section>
    );
}

export default NewProfileScreen;