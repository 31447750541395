import React, { useEffect, useState } from 'react'
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import { getAuth } from "firebase/auth";
import "firebase/compat/firestore";
import "firebase/compat/storage";
import "firebase/compat/functions";
import axios from 'axios';
import Sender from '../components/Sender';
import Reciever from '../components/Receiver';
import firebaseConfig from '../firebase_config';

function MessageScreen(props) {
  const [user, setUser] = useState();
  const [messages, setMessages] = useState([]);
  const [input, setInput] = useState('');

  useEffect(() => {
    try {
      if (firebase.apps.length === 0) {
        firebase.initializeApp(firebaseConfig);
      }
      setUser(getAuth().currentUser);
    } catch (err) {
    }
  }, []);

  useEffect(() => {
    // navigation.setOptions({
    //   headerShown: true,
    //   headerTitle: props.route.params.username,
    //   headerLeft: () => <TouchableOpacity onPress={() => navigation.goBack()}
    //     style={{ marginLeft: 18 }}
    //   >
    //     <MaterialIcons name="keyboard-backspace" size={30} color={scheme === 'dark' ? 'blue' : '#023535'} />
    //   </TouchableOpacity>,
    //   headerRight: () => (
    //     <TouchableOpacity onPress={dialCall} style={theme.callIcon}>
    //       <Foundation name="telephone" size={30} color="#ef4444" />
    //     </TouchableOpacity>
    //   )
    // });

    // if (props.route.params.read === 'true') {
    //   firebase
    //     .firestore()
    //     .collection('messages')
    //     .doc(props.route.params.collectionId)
    //     .collection('messages')
    //     .doc(props.route.params.messageId)
    //     .update({ read: false })
    //     .catch(err => { });
    // }

    const collectionId = '';

    if (!collectionId) {
      return;
    }

    const unSub = firebase
      .firestore()
      .collection('messages')
      .doc(collectionId)
      .collection('messages')
      .orderBy('created_at', 'desc')
      .onSnapshot(querySnapshot => {
        const messagesArray = [];

        querySnapshot.forEach(item => {
          const { message, created_at, userId } = item.data();

          messagesArray.push({
            id: item.id,
            message,
            created_at,
            userId
          });
        });

        setMessages(messagesArray);
      },
        (error) => { }
      );

    return () => unSub();
  }, []);

  const sendMessage = async (predefinedMessage = null) => {
    try {
      if (predefinedMessage) {
        setInput(predefinedMessage);
      }

      const value = predefinedMessage || input;

      const messageCollection = firebase
        .firestore()
        .collection('messages')
        .doc(props.route.params.collectionId);

      const messageId = await messageCollection
        .collection('messages')
        .add({
          message: value,
          created_at: firebase.firestore.Timestamp.now(),
          userId: user.uid,
          read: true
        });

      // connect users
      console.log('Connecting users..', [user.uid, props.route.params.userId]);
      await messageCollection.set({
        expired: false,
        users: [user.uid, props.route.params.userId]
      }, { merge: true });

      firebase
        .firestore()
        .collection('users')
        .doc(props.route.params.userId)
        .get()
        .then(async documentSnapshot => {
          if (documentSnapshot.exists) {
            if (documentSnapshot.data().token) {
              if (documentSnapshot.data().device === 'android') {
                console.log('Preparing for android push');
                const data = JSON.stringify({
                  "message": {
                    "token": documentSnapshot.data().token,
                    "notification": {
                      title: props.route.params.senderUserName,
                      body: value,
                    },
                    "android": {
                      priority: "high",
                      notification: {
                        defaultSound: true,
                        //sound: "default",
                      },
                    },
                    "data": {
                      username: props.route.params.senderUserName,
                      collectionId: props.route.params.collectionId,
                      photoURL: user.photoURL,
                      read: 'true',
                      messageId: messageId.id,
                      userId: user.uid,
                      senderUserName: props.route.params.username,
                      type: props.route.params.type === 'host' ? 'trip' : 'host',
                      notification: 'true'
                    },
                  }
                });

                const currentUser = firebase.auth().currentUser;
                const idToken = await currentUser.getIdToken(true);

                const config = {
                  method: 'post',
                  url: process.env.REACT_APP_CANCELLY_API_URL + '/notify/android',
                  headers: {
                    'Content-Type': 'application/json',
                    Authorization: ` Bearer ${idToken}`,
                  },
                  data: data
                };

                axios(config)
                  .then(function (response) {
                  })
                  .catch(function (error) {
                  });
              } else {
                console.log('Preparing for iphone push');
                // FIXME: use $process.env.REACT_APP_CANCELLY_API_URL/notify/ios
                const sendApnNotification = firebase.functions().httpsCallable('sendApnNotification');
                sendApnNotification({
                  title: props.route.params.senderUserName,
                  message: value,
                  token: documentSnapshot.data().token,
                  IS_PRODUCTION: !(!process.env.NODE_ENV || process.env.NODE_ENV === 'development'),
                  username: props.route.params.senderUserName,
                  collectionId: props.route.params.collectionId,
                  photoURL: user.photoURL,
                  read: 'true',
                  messageId: messageId.id,
                  userId: user.uid,
                  senderUserName: props.route.params.username,
                  type: props.route.params.type === 'host' ? 'trip' : 'host',
                  notification: 'true'
                })
                  .then(resp => { })
                  .catch(err => { })
              }
            }
          }
        }).catch(err => { });

      setInput('');
    } catch (err) {
      console.error(err);
    }
  }

  return (
    <div className='container'>
      <ul>
        {messages.map(item => {
          return (
            item.userId === user?.uid ?
              <Sender message={item.message} created_at={item.created_at} />
              :
              <Reciever photoURL={props.route.params.photoURL} message={item.message} created_at={item.created_at} />
          )
        })}
      </ul>

      <div className='inputView'>
        <textarea
          value={input}
          onChangeText={setInput}
          placeholder='Send Message...'
        //placeholderTextColor={scheme === 'dark' ? 'grey' : '#000'}
        //style={theme.input}
        //multiline={true}
        ></textarea>
        <button
          disabled={input.length > 0 ? false : true}
          title='Send'
          color='blue'
          onPress={() => sendMessage()}
        ></button>
      </div>
    </div>
  )
}

export default MessageScreen;