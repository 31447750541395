import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { CiLink } from "react-icons/ci";
import { getAuth, onAuthStateChanged } from "firebase/auth";
// import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";

function DataRequestScreen() {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [user, setUser] = useState(null);
    const [business, setBusiness] = useState(null);
    const [institution, setInstitution] = useState(null);

    useEffect(() => {
        const auth = getAuth();
        onAuthStateChanged(auth, (user) => {
            setUser(user);
        });
    }, []);

    useEffect(() => {
        setBusiness({
            dba: 'Ryde',
            logo: '/assets/web/meetryde_logo.png',
            privacyPolicy: 'https://www.riamuapp.com/Privacy.html',
        });
        setInstitution({
            name: 'SAAQClic',
            type: 'cancelly_extension',
            logo: '/assets/web/saaqclic_logo.svg',
            country_code: 'CA',
        });
    }, []);

    const launch = async () => {
        if (!user) {
            alert('No user found. Are you logged in?');
            return;
        }
        const idTokenResult = await user.getIdTokenResult();
        const sender = {
            action: 'institution_connect',
            body: {
                token: idTokenResult.token,
                institution,
            }
        };
        if (window.hasOwnProperty('chrome') && chrome.hasOwnProperty('runtime') && chrome.runtime.hasOwnProperty('sendMessage')) {
            console.log('Chrome Runtime is available');
            try {
                window.chrome.runtime.sendMessage("bdehkmgfjppeagefkipigfmlnginebkd", sender); /* global chrome */
                window.chrome.runtime.sendMessage("jlmgalhiiblhpgkemdoajofnbmhkoplg", sender); /* global chrome */
            } catch (err) {
                console.log('Error sending message to chrome extension', err);
                alert('Requires Cancelly App or Chrome Extension');
            }
        } else if (window.hasOwnProperty('ReactNativeWebView') && window.ReactNativeWebView.hasOwnProperty('postMessage')) {
            console.log('React Native runtime is available');
            try {
                window.ReactNativeWebView.postMessage(JSON.stringify(sender));
            } catch (err) {
                console.log('Error sending message to native app', err);
                alert('Requires Cancelly App or Chrome Extension');
            }
        } else {
            console.error('window.ReactNativeWebView.postMessage or chrome.runtime.sendMessage does not exists');
            alert('Requires Cancelly App or Chrome Extension');
        }
    };

    if (!business || !institution) {
        return <>No request found</>;
    }

    return (
        <div className="text-white max-w-lg lg:w-full m-auto shadow-sm">
            <div className="text-left mt-6 ml-8 mr-8 mb-6 bg-white text-black rounded-2xl">
                <h2 className="text-center font-bold border-b-2 p-4 select-none">{business.dba} wants to connect with {institution.name}</h2>
                <div className="flex font-bold border-b-2 p-2 select-none h-[16]">
                    <div className="flex-1 flex justify-center">
                        <img src={business.logo} className="h-14 self-center" />
                    </div>
                    <div className="flex-1 flex justify-center text-6xl font-bold">
                        <CiLink className="self-center" />
                    </div>
                    <div className="flex-1 flex justify-center">
                        <img src={institution.logo} className="h-14 self-center" />
                    </div>
                </div>
                <div className="px-4 py-2">
                    <ol className="list-decimal my-2 mx-4 leading-10">
                        <li>
                            <h3 className="font-bold">Fast and secure</h3>
                            <p className="leading-5">This usually takes less than a few minutes and is encrypted</p>
                        </li>
                        <li>
                            <h3 className="font-bold">How does it work</h3>
                            <p className="leading-5">Use Chrome, Chromium Browser or Cancelly iPhone or Android App</p>
                        </li>
                        <li>
                            <h3 className="font-bold">How we protect your privacy</h3>
                            <p className="leading-5">To learn how our service provider uses data you provide and device data, see their <a href={business.privacyPolicy} target="_blank" rel="noreferrer" className="text-blue-600 underline">Privacy Statement</a></p>
                        </li>
                    </ol>
                    <div className="flex justify-center">
                        <button onClick={() => launch()} className="bg-black text-white text-xl font-bold rounded-lg py-2 px-4 mt-6 hover:text-slate-400">Start</button>
                    </div>
                </div>
                <div class="text-center mt-4 p-1 text-sm">
                    <p>Powered by Cancelly</p>
                </div>
            </div>
        </div>
    );
}

export default DataRequestScreen;
