import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {
    getAuth,
    isSignInWithEmailLink,
    signInWithEmailLink,
    onAuthStateChanged,
} from "firebase/auth";

function Redirect() {
    const navigate = useNavigate();
    
    useEffect(() => {
        const auth = getAuth();
        onAuthStateChanged(auth, (user) => {
            if (user) {
                const email = user.email;
                localStorage.setItem('email', email);
                navigate("/dashboard"); // FIXME: change to /dashboard 
            } else if (window.innerWidth <= 768 || (window.hasOwnProperty('ReactNativeWebView') && window.ReactNativeWebView.hasOwnProperty('postMessage'))) {
                localStorage.removeItem('email');
                navigate("/phone");
            } else {
                localStorage.removeItem('email');
                navigate("/home");
            }
        });
    }, []);

    useEffect(() => {
        // Confirm the link is a sign-in with email link.
        const auth = getAuth();
        if (isSignInWithEmailLink(auth, window.location.href)) {
            // Additional state parameters can also be passed via URL.
            // This can be used to continue the user's intended action before triggering
            // the sign-in operation.
            // Get the email if available. This should be available if the user completes
            // the flow on the same device where they started it.
            let email_ = localStorage.getItem('email');
            if (!email_) {
                // User opened the link on a different device. To prevent session fixation
                // attacks, ask the user to provide the associated email again. For example:
                email_ = window.prompt('Please provide your email for confirmation');
            }
            // The client SDK will parse the code from the link for you.
            signInWithEmailLink(auth, email_, window.location.href)
                .then((result) => {
                    // Clear email from storage.
                    //localStorage.removeItem('email');

                    // You can access the new user via result.user
                    // Additional user info profile not available via:
                    // result.additionalUserInfo.profile == null
                    // You can check if the user is new or existing:
                    // result.additionalUserInfo.isNewUser
                })
                .catch((error) => {
                    // Some error occurred, you can inspect the code: error.code
                    // Common errors could be invalid email and invalid or expired OTPs.
                    const errorCode = error.code;
                    // const errorMessage = error.message;
                    if (errorCode === "auth/invalid-action-code") {
                        navigate("/404");
                    }
                });
        }
    }, [navigate]);
}

export default Redirect;
