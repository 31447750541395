import { getAuth, isSignInWithEmailLink, signInWithEmailLink } from 'firebase/auth';

function VerifyEmail() {
    const submitForm = async (event) => {
        event.preventDefault();

        const verifyOtpError = document.getElementById('verifyOtpError');
        if (!verifyOtpError.classList.contains('hidden')) {
            verifyOtpError.classList.add('hidden');
        }
        verifyOtpError.innerText = '';

        const url = document.getElementsByName('otp')[0].value;

        try {
            const auth = getAuth();
            if (isSignInWithEmailLink(auth, url)) {
                let email = localStorage.getItem('email');
                if (!email) {
                    email = window.prompt('Please provide your email for confirmation');
                }
                await signInWithEmailLink(auth, email, url);
                window.location = '/dashboard';
            }
        } catch (err) {
            verifyOtpError.innerText = err.toString().replace(/firebase/gi, '');
            if (verifyOtpError.classList.contains('hidden')) {
                verifyOtpError.classList.remove('hidden');
            }
        }

        return false;
    };

    return (
        <div className="App-Component component-wrapper">
            <div className="mt-8">
                <h2 className="text-3xl">Great! Let's verify your email for security.</h2>
                <p className="mt-5 text-3xl">{localStorage.getItem('email')}</p>
                <p className="mt-5 text-4xl text-bold">Click the link in email or paste below</p>
                <div className="mt-2 align-center block">
                    <form action="/" type="post" onSubmit={submitForm}>
                        <input
                            name="otp"
                            type="text"
                            placeholder="https://cancelly.ca/?apiKey=aOjcaLyDLBDHtEe9DKsSciMJBIzAUceOS6cU9zz&mode=signIn&oobCode=4ISuKviEAANZmv5PAHkGscuIoUZP-lNA9d6VluRAo-8AIvI0w2xlai&continueUrl=https://cancelly.ca/homeverify&lang=en"
                            pattern="[Hh][Tt][Tt][Pp][Ss]?:\/\/(?:(?:[a-zA-Z\u00a1-\uffff0-9]+-?)*[a-zA-Z\u00a1-\uffff0-9]+)(?:\.(?:[a-zA-Z\u00a1-\uffff0-9]+-?)*[a-zA-Z\u00a1-\uffff0-9]+)*(?:\.(?:[a-zA-Z\u00a1-\uffff]{2,}))(?::\d{2,5})?(?:\/[^\s]*)?"
                            className="mt-4 p-4 min-w-[250px] text-black"
                            required />
                        <button
                            type="submit"
                            className="bg-black mt-4 p-4 border-solid border-slate-600 hover:text-slate-400">Verify</button>
                    </form>
                </div>
                <p id="verifyOtpError" className="text-red-600 hidden"></p>
                <p className="mt-5">
                    Cancelly works best with Chrome Extension
                </p>
                <p>
                    <a className="underline" target="_blank"
                        href="https://chrome.google.com/webstore/detail/cancelly/bdehkmgfjppeagefkipigfmlnginebkd">Visit Chrome Web Store To Download</a>
                </p>
            </div>
        </div>
    );
}

export default VerifyEmail;
