import React from 'react'
import { extendMoment } from 'moment-range';
import Moment from "moment";

import './styles.css';

const moment = extendMoment(Moment);

const Sender = ({ message, created_at }) => {

  return (
    <div className='container'>
      <div className='messageView'>
        <p className='messageText'>
          {message}
        </p>
        <p className='messageText' style={{ fontSize: 12, textAlign: 'right', marginLeft: 20, alignSelf: 'flex-end', marginTop: 15 }}>
          {moment.unix(created_at).format("hh:mm A")}
        </p>
      </div>
    </div>
  )
}

export default Sender;
