import React, { useState } from 'react'
import { extendMoment } from 'moment-range';
import Moment from "moment";

import './styles.css';

const moment = extendMoment(Moment);

const Reciever = ({ photoURL, message, created_at }) => {
  const [loading, setLoading] = useState(false);

  return (
    <div className='container'>
      <img hidden={!loading} className='indicator' />
      <img
        src={photoURL || 'https://firebasestorage.googleapis.com/v0/b/riamu-8bf19.appspot.com/o/template%2Fblank-profile-picture-973460_1280.webp?alt=media&token=924f1101-8b19-4bc6-8f79-9d9c6c662544'}
        className='image'
        onLoadStart={() => setLoading(true)}
        //resizeMode={FastImage.resizeMode.cover}
        onLoadEnd={() => setLoading(false)}
      />
      <div className='messageView'>
        <p className='messageText'>
          {message}
        </p>
        <p className='messageText' style={{ textAlign: 'right', fontSize: 12, marginLeft: 20, alignSelf: 'flex-end', marginTop: 10 }}>
          {moment.unix(created_at).format("hh:mm A")}
        </p>
      </div>
    </div>
  )
}

export default Reciever;
