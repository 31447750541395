import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';

import firebaseConfig from '../firebase_config';

import './style.css';

function ApiKeysScreen() {
    const navigate = useNavigate();
    const selectRef = React.createRef();
    const [user, setUser] = useState();
    const [businesses, setBusinesses] = useState([]);
    const [selectedBusiness, setSelectedBusiness] = useState(null);
    const [keys, setKeys] = useState(null);
    const [processing, setProcessing] = useState(false);

    useEffect(() => {
        if (!firebase.apps.length) {
            firebase.initializeApp(firebaseConfig);
        }
        setUser(firebase.auth().currentUser);
    }, []);

    useEffect(() => {
        if (!user) {
            return;
        }
        const unsubscribe = firebase.firestore().collection('business')
            .where('userId', '==', user.uid)
            .onSnapshot((businessesSnapshot) => {
                if (businessesSnapshot.size === 0) {
                    return;
                }

                const businessList = [];
                for (let i in businessesSnapshot.docs) {
                    const business = businessesSnapshot.docs[i];
                    businessList.push({
                        id: business.id,
                        ...business.data(),
                    });
                }

                if (businessList.length > 0) {
                    setSelectedBusiness(businessList[0]);
                }

                setBusinesses(businessList);
            });

        return () => {
            unsubscribe();
        };
    }, [user]);

    useEffect(() => {
        if (!selectedBusiness) {
            return;
        }
        const unsubscribe = firebase.firestore().collection('apikeys')
            .where('businessId', '==', selectedBusiness.id)
            .orderBy('createdOn', 'desc')
            .limit(1)
            .onSnapshot((keysSnapshot) => {
                if (keysSnapshot.size === 0) {
                    return;
                }

                for (let i in keysSnapshot.docs) {
                    const key = keysSnapshot.docs[i];
                    setKeys({
                        id: key.id,
                        ...key.data(),
                    });
                }
            });

        return () => {
            unsubscribe();
        };
    }, [selectedBusiness]);

    const selectBusiness = (e) => {
        setSelectedBusiness(businesses && businesses[parseInt(e.target.value)] && businesses[parseInt(e.target.value)]);
    };

    const toggleType = () => {
        var input = document.getElementById("secretKey");
        if (input.type === "password") {
            input.type = "text";
        } else {
            input.type = "password";
        }
    };

    const generateKeys = async (e) => {
        if (!selectedBusiness) {
            alert('No business found');
            return;
        }
        if (keys) {
            if (!window.confirm('This action can\'t be reversed. API Keys will be lost forever. Are you sure?')) {
                return;
            }
            await firebase.firestore().collection('apikeys').doc(keys.id).delete();
        }
        await firebase.firestore().collection('apikeys').add({
            businessId: selectedBusiness.id,
            createdOn: firebase.firestore.Timestamp.now(),
            apikey: 'Loading...', // do it on the server
            secret: 'Loading...', // do it on the server 
        });
        navigate('/api-keys');
    };

    return (
        // <div className="App-Component component-wrapper w-[600px] w-full m-auto mt-8">
        //     <h3 className="text-xl mb-4">Request data from your users with their consent</h3>
        //     <div className="text-justify mt-8">
        //         <p>Step 1: <a href="/business-profile" className="underline">Create a business profile</a></p>
        //         <p>Step 2: <a href="/api-keys" className="underline">Get API Keys</a></p>
        //         <p>Step 3: <a href="/api-keys" className="underline">Create a new request link to share with user</a></p>
        //         <div>

        //         </div>
        //         <p>Step 4: <a href="/api-keys" className="underline">Check for response</a></p>
        //     </div>
        // </div>

        <div className="max-w-4xl m-auto p-6">
            <div className="mt-4">
                <h3 className="text-white text-xl mb-8">API Keys</h3>
                {businesses && businesses.length > 0
                    ? <div className="grid grid-cols-2 lg:grid-cols-1 gap-6">
                        <div className="text-right lg:text-center">
                            <label className="text-white mr-2">Business Name:</label>
                        </div>
                        <div className="text-left lg:text-center">
                            <select ref={selectRef} value={selectedBusiness} onChange={selectBusiness}
                                className="w-full break-word">
                                {businesses.map((business, index) => {
                                    return <option key={index}>{business.legalName} - {business.dba} {business.approved ? '' : '(pending approval)'}</option>
                                })}
                            </select>
                        </div>

                        <div className="text-right lg:text-center">
                            <label for="apiKey" className="text-white mr-2">API Key:</label>
                        </div>
                        <div className="text-left lg:text-center">
                            <input id="apiKey" name="apiKey" value={selectedBusiness && selectedBusiness.approved && keys ? keys.apikey : ''}
                                readOnly disabled={selectedBusiness && selectedBusiness.approved === false}
                                className="w-[300px] p-2 disabled:bg-gray-400" />
                        </div>

                        <div className="text-right lg:text-center">
                            <label for="secretKey" className="text-white mr-2">Secret Key:</label>
                        </div>
                        <div className="text-left lg:text-center">
                            <input id="secretKey" name="secretKey" type="password" value={selectedBusiness && selectedBusiness.approved && keys ? keys.secret : ''}
                                readOnly disabled={selectedBusiness && selectedBusiness.approved === false}
                                onFocus={toggleType} onBlur={toggleType} className="w-[300px] p-2 disabled:bg-gray-400" />
                        </div>

                        <div className="text-center col-span-2 lg:col-span-1">
                            <button type="button" disabled={(selectedBusiness && selectedBusiness.approved === false) || processing} onClick={generateKeys}
                                className="px-8 py-2.5 leading-5 text-white transition-colors duration-300 transform bg-gray-700 rounded-md hover:bg-gray-600 focus:outline-none focus:bg-gray-600 disabled:bg-gray-400">
                                {selectedBusiness && selectedBusiness.approved && keys ? 'Reset' : 'Generate'}</button>
                        </div>

                        <div className="text-white text-center col-span-2 lg:col-span-1">
                            <p>* Never share your keys publicly</p>
                        </div>
                    </div> : <div className="text-white">
                        <p>No business found</p>
                        <br />
                        <a href="/business-profile" className="underline">Please create your business profile from business profile page</a>
                    </div>}

            </div>
        </div>
    );
}

export default ApiKeysScreen;
