import qs from 'qs';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Buffer } from 'buffer';
import ReCAPTCHA from "react-google-recaptcha";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import firebaseConfig from '../firebase_config';
import './style.css';

function BusinessProfile() {
    const navigate = useNavigate();
    const [user, setUser] = useState();
    const [businesses, setBusinesses] = useState([]);
    const [logo, setLogo] = useState();
    const [startDate, setStartDate] = useState();
    const [legalName, setLegalName] = useState();
    const [dba, setDBA] = useState();
    const [certificate, setCertificate] = useState();
    const [article, setArticle] = useState();
    const [website, setWebsite] = useState();
    const [privacyPolicy, setPrivacyPolicy] = useState();
    const [title, setTitle] = useState();
    const [email, setEmail] = useState();
    const [emailVerified, setEmailVerified] = useState(false);
    const [phoneNumber, setPhoneNumber] = useState(); // +16473629644
    const [phoneNumberVerified, setPhoneNumberVerified] = useState(false);
    const [phoneOTP, setPhoneOTP] = useState();
    const [phoneVerification, setPhoneVerification] = useState();
    const [approved, setApproved] = useState(false);
    const [processing, setProcessing] = useState(false);
    const recaptchaRef = React.createRef();

    useEffect(() => {
        if (!firebase.apps.length) {
            firebase.initializeApp(firebaseConfig);
        }
        setUser(firebase.auth().currentUser);
    }, []);

    useEffect(() => {
        if (!user) {
            return;
        }
        const unsubscribe = firebase.firestore().collection('business')
            .where('userId', '==', user.uid)
            .where('approved', '==', true)
            .onSnapshot((businessesSnapshot) => {
                if (businessesSnapshot.size === 0) {
                    return;
                }

                const businessList = [];
                for (let i in businessesSnapshot.docs) {
                    const business = businessesSnapshot.docs[i];
                    businessList.push({
                        id: business.id,
                        ...business.data(),
                    });
                }
                setBusinesses(businessList);

                if (businessList && businessList.length > 0) {
                    const business = businessList[0];
                    setLogo(business.logo);
                    setStartDate(business.startDate);
                    setLegalName(business.legalName);
                    setDBA(business.dba);
                    setCertificate(business.certificate);
                    setArticle(business.article);
                    setWebsite(business.website);
                    setPrivacyPolicy(business.privacyPolicy);
                    setTitle(business.title);
                    setEmail(business.email);
                    setEmailVerified(business.emailVerified);
                    setPhoneNumber(business.phone);
                    setPhoneNumberVerified(business.phoneNumberVerified);
                    setApproved(business.approved);
                }
            });

        return () => {
            unsubscribe();
        };
    }, [user]);

    const uploadImageAsync = async (file, prefix = null) => {
        try {
            const auth = firebase.auth();
            const _prefix = prefix || "user/" + auth.currentUser.uid + "/";
            const storage = getStorage();
            const storageRef = ref(storage, _prefix + file.name);
            const snapshot = await uploadBytes(storageRef, file);
            const downloadURL = await getDownloadURL(snapshot.ref);
            return downloadURL;
        } catch (err) {
            throw new Error(err)
        }
    };

    const sendOTP = async (event) => {
        event.preventDefault();

        if (phoneNumber === '') {
            return alert('Enter Phone Number.');
        }

        const token = await recaptchaRef.current.executeAsync();
        console.log('Caotcha token', token);

        const data = qs.stringify({
            'To': phoneNumber,
            'Channel': 'sms'
        });

        const authHeader = 'Basic ' + Buffer.from(`${process.env.TWILIO_ACCOUNT_SID}:${process.env.TWILIO_AUTH_TOKEN}`).toString('base64');

        const config = {
            method: 'post',
            url: 'https://verify.twilio.com/v2/Services/VA572038bc91e35ae2849432653f56faab/Verifications',
            headers: {
                'Authorization': authHeader,
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            data: data
        };

        fetch(config)
            .then(function (response) {
                console.log(response);
            })
            .catch(function (error) {
                alert('Error sending Phone OTP code');
            });
    };

    const verifyPhoneNumber = async (otp = phoneOTP) => {
        if (otp === '') {
            return alert('Enter Phone OTP Code');
        }

        setProcessing(true);

        var data = qs.stringify({
            'To': phoneNumber,
            'Code': otp
        });

        const authHeader = 'Basic ' + Buffer.from(`${process.env.TWILIO_ACCOUNT_SID}:${process.env.TWILIO_AUTH_TOKEN}`).toString('base64');

        const config = {
            method: 'post',
            url: 'https://verify.twilio.com/v2/Services/VA572038bc91e35ae2849432653f56faab/VerificationCheck',
            headers: {
                'Authorization': authHeader,
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            data: data
        };

        try {
            const { status } = await fetch(config);

            if (status !== 'approved') {
                return false;
            }
            return true;

        } catch (err) {
            console.error(err);
            return false;

        } finally {
            setProcessing(false);
        }
    };

    const onSubmit = async (event) => {
        let newBusiness;

        try {
            event.preventDefault();

            setProcessing(true);

            // const isPhoneNumberVerified = await verifyPhoneNumber();
            // setPhoneNumberVerified(isPhoneNumberVerified);
            // if (!isPhoneNumberVerified) {
            //     throw new Error('Phone number not verified');
            // }

            const userData = {
                userId: user.uid,
                approved: false,
            };

            newBusiness = await firebase.firestore().collection('business').add(userData);
            const prefex = 'business/' + newBusiness.id + '/';

            const elements = event.target.elements;

            const logos = elements.logo.files;
            if (logos.length > 0) {
                const file = logos[0];
                const url = await uploadImageAsync(file, prefex);
                if (url) {
                    userData['logo'] = url;
                }
            } else {
                throw new Error('Business logo is required');
            }

            if (startDate) {
                userData['startDate'] = elements.startDate.value;
            } else {
                throw new Error('Business start date is required');
            }

            if (elements.legalName.value) {
                userData['legalName'] = elements.legalName.value;
            } else {
                throw new Error('Business legal name is required');
            }

            if (dba) {
                userData['dba'] = elements.dba.value;
            } else {
                throw new Error('Business doing business-as name is required');
            }

            const certificates = elements.certificate.files;
            if (certificates.length > 0) {
                const file = certificates[0];
                const url = await uploadImageAsync(file, prefex + 'certificates/');
                if (url) {
                    userData['certificate'] = url;
                }
            } else {
                throw new Error('Business certificate is required');
            }

            const articles = elements.article.files;
            if (articles.length > 0) {
                const file = articles[0];
                const url = await uploadImageAsync(file, prefex + 'articles/');
                if (url) {
                    userData['article'] = url;
                }
            } else {
                throw new Error('Business article of incorporation is required');
            }

            if (website) {
                userData['website'] = elements.website.value;
            } else {
                throw new Error('Business website is required');
            }

            if (email) {
                userData['email'] = elements.email.value;
                userData['emailVerified'] = false;
            } else {
                throw new Error('Business email is required');
            }

            if (phoneNumber) {
                userData['phoneNumber'] = phoneNumber;
                userData['phoneNumberVerified'] = false;
            } else {
                throw new Error('Business phone is required');
            }

            newBusiness && await newBusiness.set(userData, { merge: true });

            if (window.confirm('Profile Saved! Press Ok to refresh the page.')) {
                navigate('/business-profile');
            }

        } catch (err) {
            console.error(err);
            newBusiness && newBusiness.delete();
            alert(err);

        } finally {
            setProcessing(false);
            return false;
        }
    }

    if (!user) {
        return;
    }

    return (
        <section class="mt-10 mb-10 mx-auto lg:mx-4 p-2 max-w-4xl p-6 bg-white rounded-md shadow-md dark:bg-gray-800">
            <h2 class="text-lg font-semibold text-gray-700 capitalize dark:text-white">Tell us about your business</h2>
            <br />
            <p>* All fields are required</p>
            <form onSubmit={onSubmit}>
                <div class="grid grid-cols-2 lg:grid-cols-1 gap-6 mt-4">
                    {logo && <div className="w-[150px] h-[150px] border-1 rounded-[75px] m-auto">
                        <img src={logo} alt="Business Logo" className="w-full" />
                    </div>}

                    {logo && <div></div>}

                    <div>
                        <label for="logo" class="text-gray-700 dark:text-gray-200">Logo</label>
                        <input id="logo" type="file" accept="image/png,image/jpeg" disabled={processing} class="block w-full px-4 py-2 text-sm text-gray-600 bg-white border border-gray-200 rounded-lg file:bg-gray-200 file:text-gray-700 file:text-sm file:px-6 file:py-1 file:border-none file:rounded-full dark:file:bg-gray-800 dark:file:text-gray-200 dark:text-gray-300 placeholder-gray-400/70 dark:placeholder-gray-500 focus:border-blue-400 focus:outline-none focus:ring focus:ring-blue-300 focus:ring-opacity-40 dark:border-gray-600 dark:bg-gray-900 dark:focus:border-blue-300" />
                    </div>

                    <div>
                        <label for="startDate" class="block text-gray-700 dark:text-gray-200">Business Start Date</label>
                        <input id="startDate" type="date" placeholder="John Doe" disabled={processing}
                            value={startDate} onChange={e => setStartDate(e.target.value)}
                            class="block w-full h-14 placeholder-gray-400/70 dark:placeholder-gray-500 rounded-lg border border-gray-200 bg-white px-5 py-2.5 text-gray-700 focus:border-blue-400 focus:outline-none focus:ring focus:ring-blue-300 focus:ring-opacity-40 dark:border-gray-600 dark:bg-gray-900 dark:text-gray-300 dark:focus:border-blue-300" />
                    </div>

                    <div>
                        <label class="text-gray-700 dark:text-gray-200" for="legalName">Legal Business Name</label>
                        <input id="legalName" type="text" placeholder="Riamu Inc." disabled={processing}
                            value={legalName} onChange={e => setLegalName(e.target.value)}
                            class="block w-full h-14 px-4 py-2 text-gray-700 bg-white border border-gray-200 rounded-md dark:bg-gray-800 dark:text-gray-300 dark:border-gray-600 focus:border-blue-400 focus:ring-blue-300 focus:ring-opacity-40 dark:focus:border-blue-300 focus:outline-none focus:ring" />
                    </div>

                    <div>
                        <label class="text-gray-700 dark:text-gray-200" for="dba">Doing Business As Name (DBA)</label>
                        <input id="dba" type="text" placeholder="Ryde" disabled={processing}
                            value={dba} onChange={e => setDBA(e.target.value)}
                            class="block w-full h-14 px-4 py-2 text-gray-700 bg-white border border-gray-200 rounded-md dark:bg-gray-800 dark:text-gray-300 dark:border-gray-600 focus:border-blue-400 focus:ring-blue-300 focus:ring-opacity-40 dark:focus:border-blue-300 focus:outline-none focus:ring" />
                    </div>

                    {certificate
                        ? <div className="w-[150px] h-[150px] border-1 rounded-[75px] m-auto">
                            <img src={certificate} alt="Business Certificate" className="w-full" />
                        </div>
                        : article && <div></div>}

                    {article
                        ? <div className="w-[150px] h-[150px] border-1 rounded-[75px] m-auto">
                            <img src={article} alt="Articles of Incorporation" className="w-full" />
                        </div>
                        : certificate && <div></div>}

                    <div>
                        <label for="certificate" class="text-gray-700 dark:text-gray-200">Valid Business Certificate</label>
                        <input id="certificate" type="file" accept="image/png,image/jpeg,application/pdf" disabled={processing} class="block w-full px-4 py-2 text-sm text-gray-600 bg-white border border-gray-200 rounded-lg file:bg-gray-200 file:text-gray-700 file:text-sm file:px-6 file:py-1 file:border-none file:rounded-full dark:file:bg-gray-800 dark:file:text-gray-200 dark:text-gray-300 placeholder-gray-400/70 dark:placeholder-gray-500 focus:border-blue-400 focus:outline-none focus:ring focus:ring-blue-300 focus:ring-opacity-40 dark:border-gray-600 dark:bg-gray-900 dark:focus:border-blue-300" />
                    </div>

                    <div>
                        <label for="article" class="text-gray-700 dark:text-gray-200">Articles of Incorporation</label>
                        <input id="article" type="file" accept="image/png,image/jpeg,application/pdf" disabled={processing} class="block w-full px-4 py-2 text-sm text-gray-600 bg-white border border-gray-200 rounded-lg file:bg-gray-200 file:text-gray-700 file:text-sm file:px-6 file:py-1 file:border-none file:rounded-full dark:file:bg-gray-800 dark:file:text-gray-200 dark:text-gray-300 placeholder-gray-400/70 dark:placeholder-gray-500 focus:border-blue-400 focus:outline-none focus:ring focus:ring-blue-300 focus:ring-opacity-40 dark:border-gray-600 dark:bg-gray-900 dark:focus:border-blue-300" />
                    </div>

                    <div>
                        <label class="text-gray-700 dark:text-gray-200" for="website">Business Website</label>
                        <input id="website" type="text" placeholder="https://www.riamuapp.com/"
                            value={website} onChange={e => setWebsite(e.target.value)} disabled={processing}
                            class="block w-full h-14 px-4 py-2 text-gray-700 bg-white border border-gray-200 rounded-md dark:bg-gray-800 dark:text-gray-300 dark:border-gray-600 focus:border-blue-400 focus:ring-blue-300 focus:ring-opacity-40 dark:focus:border-blue-300 focus:outline-none focus:ring" />
                    </div>

                    <div>
                        <label class="text-gray-700 dark:text-gray-200" for="privacyPolicy">Privacy Policy URL</label>
                        <input id="privacyPolicy" type="text" placeholder="https://www.riamuapp.com/Privacy.html"
                            value={privacyPolicy} onChange={e => setPrivacyPolicy(e.target.value)} disabled={processing}
                            class="block w-full h-14 px-4 py-2 text-gray-700 bg-white border border-gray-200 rounded-md dark:bg-gray-800 dark:text-gray-300 dark:border-gray-600 focus:border-blue-400 focus:ring-blue-300 focus:ring-opacity-40 dark:focus:border-blue-300 focus:outline-none focus:ring" />
                    </div>

                    <div>
                        <label class="text-gray-700 dark:text-gray-200" for="title">Your Title</label>
                        <input id="title" type="text" placeholder="E.g. CEO, Founder, Director, VP, Administrator, IT, etc"
                            value={title} onChange={e => setTitle(e.target.value)} disabled={processing}
                            class="block w-full h-14 px-4 py-2 mt-2 text-gray-700 bg-white border border-gray-200 rounded-md dark:bg-gray-800 dark:text-gray-300 dark:border-gray-600 focus:border-blue-400 focus:ring-blue-300 focus:ring-opacity-40 dark:focus:border-blue-300 focus:outline-none focus:ring" />
                    </div>

                    <div>
                        <label class="text-gray-700 dark:text-gray-200" for="email">Email</label>
                        <div className="flex mt-2">
                            <input id="email" type="email" placeholder="ceo@riamuapp.com"
                                value={email} onChange={e => setEmail(e.target.value)} disabled={processing}
                                class="block w-full h-14 px-4 py-2 text-gray-700 bg-white border border-gray-200 rounded-md dark:bg-gray-800 dark:text-gray-300 dark:border-gray-600 focus:border-blue-400 focus:ring-blue-300 focus:ring-opacity-40 dark:focus:border-blue-300 focus:outline-none focus:ring" />
                        </div>
                    </div>

                    <div>
                        <label class="text-gray-700 dark:text-gray-200" for="phoneNumber">Phone</label>
                        <div className="flex mt-2">
                            <input id="phoneNumber" type="text" placeholder="+16463628644"
                                value={phoneNumber} onChange={e => setPhoneNumber(e.target.value)} disabled={processing}
                                class="flex-1 h-14 px-4 py-2 text-gray-700 bg-white border border-gray-200 rounded-md dark:bg-gray-800 dark:text-gray-300 dark:border-gray-600 focus:border-blue-400 focus:ring-blue-300 focus:ring-opacity-40 dark:focus:border-blue-300 focus:outline-none focus:ring" />
                            {/* <button onClick={sendOTP} class="flex-none w-24 h-14 ml-2 px-2 py-0 my-0 leading-1 text-sm text-white ransition-colors duration-300 transform bg-gray-700 rounded-md hover:bg-gray-600 focus:outline-none focus:bg-gray-600">Send OTP</button> */}
                        </div>
                    </div>

                    {/* <div>
                        <label class="text-gray-700 dark:text-gray-200" for="phoneOTP">Phone OTP</label>
                        <input id="phoneOTP" type="text" placeholder="243657" disabled={processing}
                            value={phoneOTP} onChange={e => setPhoneOTP(e.target.value)}
                            class="block w-full h-14 px-4 py-2 mt-2 text-gray-700 bg-white border border-gray-200 rounded-md dark:bg-gray-800 dark:text-gray-300 dark:border-gray-600 focus:border-blue-400 focus:ring-blue-300 focus:ring-opacity-40 dark:focus:border-blue-300 focus:outline-none focus:ring" />
                    </div> */}

                    <div class="flex justify-end mt-6">
                        <button type="submit" disabled={processing} class="px-8 py-2.5 leading-5 text-white transition-colors duration-300 transform bg-gray-700 rounded-md hover:bg-gray-600 focus:outline-none focus:bg-gray-600">Save</button>
                    </div>
                </div>

                {/* <ReCAPTCHA
                    ref={recaptchaRef}
                    size="invisible"
                    sitekey={process.env.REACT_APP_GOOGLE_RECAPTCHA_SITE_KEY}
                /> */}
            </form>
        </section>
    );
}

export default BusinessProfile;